import React, { Fragment } from "react";
import axios from "axios";

import { useEffect } from "react";

import MainLayout from "../layouts/MainLayout";
import Header from "../components/Header";

function App() {
  console.log(process.env.REACT_APP_API_URL);
  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
      (async () => {
        try {
          await axios.post(process.env.REACT_APP_API_URL + "/token/verify/", {
            token: localStorage.getItem("access_token"),
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (e) {
          console.log("not auth");
        }
      })();
    }
  }, []);
  return (
    <MainLayout>
      <Fragment>
        <Header
          title="BIENVENUE SUR RISE !"
          subtitle="L'outil de gestion interne de My Maison In Paris"
        />
      </Fragment>
    </MainLayout>
  );
}

export default App;
