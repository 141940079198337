import http from "../utils/http-client";

const getTicket = () => {
    return http.get('/ticketings/ticket/');
}

const postTicket = (data) => {
    return http.post('/ticketings/ticket/', data);
}

const deleteTicket = (id) => {
    return http.suppress("/ticketings/ticket/" + id + "/");
}

const patchTicket = (id, data) => {
    return http.patch("/ticketings/ticket/" + id + "/", data);
}

const methods = { 
    getTicket,
    postTicket,
    deleteTicket,
    patchTicket
}

export default methods;