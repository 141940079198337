import axios from "axios";

let refresh = false;
axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error.response.status === 401 && !refresh) {
      refresh = true;
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/token/verify/",
        {
          refresh: localStorage.getItem("refresh_token"),
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        axios.defaults.headers.common["Authorization"] = `Bearer 
       ${response.data["access"]}`;
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        return axios(error.config);
      } else {
        localStorage.setItem("access_token", null);
        localStorage.setItem("refresh_token", null);
        window.location.href = "/login";
      }
    }
    refresh = false;
    return error;
  }
);
