import axios from 'axios';
import authService from '../services/auth.service';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL 
});

instance.interceptors.request.use((config) => {
    if (localStorage.getItem('access_token')) {
        config.headers['authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

let refresh = false;
axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error.response.status === 401 && !refresh) {
      refresh = true;
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/token/verify/",
        {
          refresh: localStorage.getItem("refresh_token"),
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        axios.defaults.headers.common["Authorization"] = `Bearer 
       ${response.data["access"]}`;
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        return axios(error.config);
      } else {
        localStorage.setItem("access_token", null);
        localStorage.setItem("refresh_token", null);
        window.location.href = "/login";
      }
    }
    refresh = false;
    return error;
  }
);

const get = (url, params, config = {}) => instance.get(url, { params, ...config });
const post = (url, data, config = {}) => instance.post(url, data, config);
const suppress = (url, data, config = {}) => instance.delete(url, data, config);
const patch = (url, data, config = {}) => instance.patch(url, data, config);

const methods = { get, post, suppress, patch };

export default methods;