// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import TicketList from "../components/Ticket/TicketList";
// import TicketForm from "../components/Ticket/TicketForm";
// import MainLayout from "../layouts/MainLayout";

// import Container from "@mui/material/Container"

// function Ticket() {
//   const [ticket, setTicket] = useState([]);
//   const [hotel, setHotel] = useState([]);
//   const [roomType, setRoomType] = useState([]);
//   const [room, setRoom] = useState([]);
//   const [user, setUser] = useState([]);
//   const [me, setMe] = useState([]);

//   useEffect(() => {
//     if (localStorage.getItem("access_token") === null) {
//       window.location.href = "/login";
//     } else {
//       (async () => {
//         try {
//           await axios.post(process.env.REACT_APP_API_URL + "/token/verify/", {
//             token: localStorage.getItem("access_token"),
//             headers: {
//               "Content-Type": "application/json",
//             },
//           });
//         } catch (e) {
//           console.log("not auth");
//         }
//       })();
//     }
//   }, []);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL + "/ticketings/ticket/", {
//         headers: {
//           "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//         },
//       })
//       .then((res) => {
//         setTicket(res.data);
//       })
//       .catch(() => {
//         alert("Something went wrong");
//       });
//   }, []);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL + "/PMSDataSettings/hotel/", {
//         headers: {
//           "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//         },
//       })
//       .then((res) => {
//         setHotel(res.data);
//       })
//       .catch(() => {
//         alert("Something went wrong");
//       });
//   }, []);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL + "/PMSDataSettings/roomType/", {
//         headers: {
//           "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//         },
//       })
//       .then((res) => {
//         setRoomType(res.data);
//       })
//       .catch(() => {
//         alert("Something went wrong");
//       });
//   }, []);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL + "/PMSDataSettings/room/", {
//         headers: {
//           "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//         },
//       })
//       .then((res) => {
//         setRoom(res.data);
//       })
//       .catch(() => {
//         alert("Something went wrong");
//       });
//   }, []);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL + "/users/user/", {
//         headers: {
//           "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//         },
//       })
//       .then((res) => {
//         setUser(res.data);
//       })
//       .catch(() => {
//         alert("Something went wrong");
//       });
//   }, []);

//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_API_URL + "/users/me/", {
//         headers: {
//           "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//         },
//       })
//       .then((res) => {
//         setMe(res.data);
//       })
//       .catch(() => {
//         alert("Something went wrong");
//       });
//   }, []);

//   return (
//     <MainLayout>
//       <Container>
//         <TicketForm ticket={ticket} setTicket={setTicket} hotel={hotel} roomType={roomType} room={room} user={user} me={me}/>
//         <TicketList ticket={ticket} setTicket={setTicket} hotel={hotel} room={room} user={user} me={me}/>
//       </Container>
//     </MainLayout>
//   );
// }

// export default Ticket;
import React, { Fragment } from "react";
import axios from "axios";

import { useEffect } from "react";

import MainLayout from "../layouts/MainLayout";
import Ticketing from "../components/ticketing";

import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

function Ticket() {
  const theme = useTheme();
  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
      (async () => {
        try {
          await axios.post(process.env.REACT_APP_API_URL + "/token/verify/", {
            token: localStorage.getItem("access_token"),
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (e) {
          console.log("not auth");
        }
      })();
    }
  }, []);
  return (
    <MainLayout>
      <Fragment>
          <Ticketing />
      </Fragment>
    </MainLayout>
  );
}

export default Ticket;
