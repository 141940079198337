import React from "react";
import Container from "react-bootstrap/Container";
import LoginForm from "../components/User/LoginForm";

function Login() {
  return (
    <Container>
      <LoginForm></LoginForm>
    </Container>
  );
}

export default Login;
