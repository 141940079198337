import http from "../utils/http-client";

const getHotel = () => {
    return http.get('/PMSDataSettings/hotel/');
}

const getRoomType = () => {
    return http.get('/PMSDataSettings/roomType/');
}

const getRoom = () => {
    return http.get('/PMSDataSettings/room/');
}

const methods = { 
    getHotel,
    getRoomType,
    getRoom
}

export default methods;