import http from "../utils/http-client";

const login = (data) => {
    return http.post('/token/', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            localStorage.setItem('authUser', JSON.stringify(parsed));
            return parsed;
        }]
    });
}

const verify = (data) => {
    return http.post('/token/verify/', data);
}

// const refresh = (refresh_token) => {
//     return http.post('/token/verify/', refresh_token, {
//         transformResponse: [(result) => {
//             const parsed = JSON.parse(result);
//             localStorage.setItem('authUser', JSON.stringify(parsed));
//             return parsed;
//         }]
//     });
// }

const register = (data) => {
    return http.post('/users/register', data);
}

const profile = () => {
    return http.get('/users/me/');
}

const getUser = () => {
    return http.get('/users/user/');
}

const logout = () => {
    return http.get('/logout/', null, {
        transformResponse: [(result) => {
            localStorage.removeItem('authUser');
            return JSON.parse(result);
        }]
    });
}

const getAuthUser = () => {
    return localStorage.getItem('access_token');
}  

const methods = { 
    login,
    register,
    verify,
    // refresh,
    profile,
    getUser,
    logout,
    getAuthUser
}

export default methods;