import React from "react";
import Container from "react-bootstrap/Container";
import RegisterForm from "../components/User/RegisterForm";

function Register() {
  return (
    <Container>
      <RegisterForm></RegisterForm>
    </Container>
  );
}

export default Register;