import React, { Fragment } from "react";
import AppBar from "../components/global/AppBar";

import Container from "@mui/material/Container"


function MainLayout({ children }) {
  return (
    <Fragment>
      <AppBar></AppBar>
      <Container maxWidth={false} sx={{ width: "95%" }}>{children}</Container>
    </Fragment>
  );
}

export default MainLayout;
