import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import ticketingService from '../../services/ticketing.service';
import authService from '../../services/auth.service';
import pmsDataSettingsService from '../../services/pmsDataSettings.service';
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography"
import Modal from '@mui/material/Modal';
import { tokens } from "../../theme";
import Header from "../Header";
import { useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TableSortLabel from '@mui/material/TableSortLabel';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';

import { red, green, blue } from '@mui/material/colors';

const checkoutSchema = yup.object().shape({
  label: yup.string().required("required"),
  type: yup.string().required("required"),
  priority: yup.string().required("required"),
  hotel: yup.string().required("required"),
  room: yup.string(),
  detail: yup.string().required("required"),
  technician: yup.string(),
  observations: yup.string(),
});
const initialValues = {
  label: "",
  type: "",
  priority: "",
  hotel: "",
  room: null,
  detail: "",
  technician: "",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map(el => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID"
  },
  { id: "label", numeric: false, disablePadding: false, label: "Nom" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  { id: "priority", numeric: false, disablePadding: false, label: "Priorité" },
  { id: "hotel", numeric: false, disablePadding: false, label: "Résidence" },
  { id: "room", numeric: false, disablePadding: false, label: "Chambre" },
  { id: "detail", numeric: false, disablePadding: false, label: "Détails" },
  { id: "observation", numeric: false, disablePadding: false, label: "Commentaires" },
  { id: "technician", numeric: false, disablePadding: false, label: "Intervenant" },
  { id: "created_by", numeric: false, disablePadding: false, label: "Créé par" },
  { id: "created_at", numeric: false, disablePadding: false, label: "Date de création" },
  { id: "closed_by", numeric: false, disablePadding: false, label: "Cloturé par" },
  { id: "closed_at", numeric: false, disablePadding: false, label: "Date de cloture" },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

const Ticketing = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [record, setRecord] = useState(null);

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openModify, setOpenModify] = React.useState(false);
  const handleOpenModify = () => setOpenModify(true);
  const handleCloseModify = () => setOpenModify(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCloseTicket = () => {
    record.closed_by = profile.username;
    record.closed_at = new Date().toISOString();
    record.closed = true;
    handleUpdate(record.id, record);
    handleCloseDialog();
  }

  const [ticket, setTicket] = useState(null);

  const fetchTicket = async () => {
    try {
      const result = await ticketingService.getTicket();
      setTicket(result.data);
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  const [profile, setProfile] = useState(null);

  const fetchProfile = async () => {
    try {
      const result = await authService.profile();
      setProfile(result.data);
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const result = await authService.getUser();
      setUser(result.data);
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  const [hotel, setHotel] = useState(null);

  const fetchHotel = async () => {
    try {
      const result = await pmsDataSettingsService.getHotel();
      setHotel(result.data);
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  const [roomType, setRoomType] = useState(null);

  const fetchRoomType = async () => {
    try {
      const result = await pmsDataSettingsService.getRoomType();
      setRoomType(result.data);
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  const [room, setRoom] = useState(null);

  const fetchRoom = async () => {
    try {
      const result = await pmsDataSettingsService.getRoom();
      setRoom(result.data);
    } catch (error) {
      toast.error(error.data.message);
    }
  }

  useEffect(() => {
    fetchProfile();
    fetchUser();
    fetchTicket();
    fetchHotel();
    fetchRoomType();
    fetchRoom();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      ticketingService.postTicket(
        {
          label: values.label,
          type: values.type,
          detail: values.detail,
          observation: values.observation,
          created_by: profile.username,
          hotel: values.hotel,
          room: values.room,
          technician: values.technician,
          closed_at: null,
          created_at: Date.now(),
          priority: values.priority,
          closed_by: null,
          closed: false
        }
      ).then((res) => {
        const { data } = res;
        setTicket([...ticket, data]);
      })
    } catch (error) {
      toast.error(error.data.message);
    }
    handleCloseAdd();
  };

  const handleSavesChange = (values) => {
    handleUpdate(record.id, values);
    handleCloseModify();
  };

  const handleDelete = async (id) => {
    try {
      ticketingService.deleteTicket(id);
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const handleUpdate = async (id, data) => {
    try {
      ticketingService.patchTicket(id, data).then((res) => {
        const { data } = res;
        const newTicket = ticket.map((t) => {
          if (t.id === id) {
            return data;
          }
          return t;
        });
        setTicket(newTicket);
      })
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  return (
    <Box>
      <Header
        title="TICKETING"
        subtitle="Liste des tickets de maintenance My Maison in Paris"
      />
      <Box m="20px 0 0 0">
        <Button 
          variant="contained"
          onClick={handleOpenAdd} 
          sx={{ 
            color: "#000000",
            backgroundColor: "#FDFE00",
            ":hover": {
              bgcolor: "#FEE471",
            }
          }}
        >
          Ajouter un ticket
        </Button>
      </Box>
      <Modal
        open={openAdd}
        onClose={handleCloseAdd}
        aria-labelledby="modal-add"
        aria-describedby="modal-add-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Nom"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.label}
                    name="label"
                    error={!!touched.label && !!errors.label}
                    helperText={touched.label && errors.label}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.type}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                    sx={{ gridColumn: "span 2" }}
                  >
                    <MenuItem value="Aucun">Aucun</MenuItem>
                    <MenuItem value="Electricite">Electricité</MenuItem>
                    <MenuItem value="Plomberie">Plomberie</MenuItem>
                    <MenuItem value="Peinture">Peinture</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Priorité"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.priority}
                    name="priority"
                    error={!!touched.priority && !!errors.priority}
                    helperText={touched.priority && errors.priority}
                    sx={{ gridColumn: "span 2" }}
                  >
                    <MenuItem value="Bas">Bas</MenuItem>
                    <MenuItem value="Moyen">Moyen</MenuItem>
                    <MenuItem value="Haut">Haut</MenuItem>
                    <MenuItem value="Urgent">Urgent</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Résidence"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.hotel}
                    name="hotel"
                    error={!!touched.hotel && !!errors.hotel}
                    helperText={touched.hotel && errors.hotel}
                    sx={{ gridColumn: "span 2" }}
                  >
                    {hotel.map((h) => {
                      return (
                        <MenuItem value={h.idMedialog}>{h.name}</MenuItem>
                      );
                    })}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Chambre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.room}
                    name="room"
                    error={!!touched.room && !!errors.room}
                    helperText={touched.room && errors.room}
                    sx={{ gridColumn: "span 2" }}
                  >
                    <MenuItem value="">Aucune</MenuItem>
                    {roomType.map((rt) => {
                      if (rt.idHotel===values.hotel) {
                        return (
                          room.map((r) => {
                            if(r.idRoomType===rt.idMedialog) {
                              return (
                                <MenuItem value={r.idMedialog}>{r.number}</MenuItem>
                              );
                            } else {
                              return null;
                            }
                          })
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="filled"
                    multiline
                    label="Détails"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.detail}
                    name="detail"
                    error={!!touched.detail && !!errors.detail}
                    helperText={touched.detail && errors.detail}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Intervenant"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.technician}
                    name="technician"
                    error={!!touched.technician && !!errors.technician}
                    helperText={touched.technician && errors.technician}
                    sx={{ gridColumn: "span 4" }}
                  >
                    {user.map((u) => {
                      return (
                        <MenuItem value={u.username}>{u.username}</MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button type="submit" variant="contained" sx={{ 
                        color: "#000000",
                        backgroundColor: "#FDFE00",
                        ":hover": {
                          bgcolor: "#FEE471",
                        }
                      }}>
                    Ajouter
                  </Button>
                </Box>
              </form>
            )}
            </Formik>
        </Box>
      </Modal>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Voulez-vous clore ce ticket ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ 
            color: "#000000",
            backgroundColor: "#FDFE00",
            ":hover": {
              bgcolor: "#FEE471",
            }
          }}
          >
            Non
          </Button>
          <Button onClick={handleCloseTicket} sx={{ 
            color: "#000000",
            backgroundColor: "#FDFE00",
            ":hover": {
              bgcolor: "#FEE471",
            }
          }}
        >
          Oui
        </Button>
        </DialogActions>
      </Dialog>
      <Modal
          open={openModify}
          onClose={handleCloseModify}
          aria-labelledby="modal-modify"
          aria-describedby="modal-modify-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Formik
              onSubmit={handleSavesChange}
              initialValues={record}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Nom"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.label}
                      name="label"
                      error={!!touched.label && !!errors.label}
                      helperText={touched.label && errors.label}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      select
                      label="Type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.type}
                      name="type"
                      error={!!touched.type && !!errors.type}
                      helperText={touched.type && errors.type}
                      sx={{ gridColumn: "span 2" }}
                    >
                      <MenuItem value="Aucun">Aucun</MenuItem>
                      <MenuItem value="Electricité">Electricité</MenuItem>
                      <MenuItem value="Plomberie">Plomberie</MenuItem>
                      <MenuItem value="Peinture">Peinture</MenuItem>
                    </TextField>
                    <TextField
                      fullWidth
                      variant="filled"
                      select
                      label="Priorité"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priority}
                      name="priority"
                      error={!!touched.priority && !!errors.priority}
                      helperText={touched.priority && errors.priority}
                      sx={{ gridColumn: "span 2" }}
                    >
                      <MenuItem value="Bas">Bas</MenuItem>
                      <MenuItem value="Moyen">Moyen</MenuItem>
                      <MenuItem value="Haut">Haut</MenuItem>
                      <MenuItem value="Urgent">Urgent</MenuItem>
                    </TextField>
                    <TextField
                      fullWidth
                      variant="filled"
                      select
                      label="Résidence"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.hotel}
                      name="hotel"
                      error={!!touched.hotel && !!errors.hotel}
                      helperText={touched.hotel && errors.hotel}
                      sx={{ gridColumn: "span 2" }}
                    >
                      {hotel.map((h) => {
                        return (
                          <MenuItem value={h.idMedialog}>{h.name}</MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      fullWidth
                      variant="filled"
                      select
                      label="Chambre"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.room}
                      name="room"
                      error={!!touched.room && !!errors.room}
                      helperText={touched.room && errors.room}
                      sx={{ gridColumn: "span 2" }}
                    >
                      <MenuItem value="">Aucune</MenuItem>
                      {roomType.map((rt) => {
                        if (rt.idHotel===values.hotel) {
                          return (
                            room.map((r) => {
                              if(r.idRoomType===rt.idMedialog) {
                                return (
                                  <MenuItem value={r.idMedialog}>{r.number}</MenuItem>
                                );
                              } else {
                                return null;
                              }
                            })
                          );
                        } else {
                          return null;
                        }
                      })}
                    </TextField>
                    <TextField
                      fullWidth
                      variant="filled"
                      multiline
                      label="Détails"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.detail}
                      name="detail"
                      error={!!touched.detail && !!errors.detail}
                      helperText={touched.detail && errors.detail}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      multiline
                      label="Commentaires"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.observation}
                      name="observation"
                      error={!!touched.detail && !!errors.detail}
                      helperText={touched.detail && errors.detail}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      select
                      label="Intervenant"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.technician}
                      name="technician"
                      error={!!touched.technician && !!errors.technician}
                      helperText={touched.technician && errors.technician}
                      sx={{ gridColumn: "span 4" }}
                    >
                      {user.map((u) => {
                        return (
                          <MenuItem value={u.username}>{u.username}</MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button type="submit" variant="contained" sx={{ 
                        color: "#000000",
                        backgroundColor: "#FDFE00",
                        ":hover": {
                          bgcolor: "#FEE471",
                        }
                      }}>
                      Modifier
                    </Button>
                  </Box>
                </form>
              )}
              </Formik>
          </Box>
        </Modal>
      <Typography 
            variant="h3" 
            m="20px 0 0 0"
        >
            Ouverts
        </Typography>
      <Box
        m="20px 0 0 0"
        height="50%"
        sx={{
          "& .MuiTableCell-head": {
            backgroundColor: "#8AA294",
          },
          "& .MuiTableCell-body": {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ticket?.length}
            />
            <TableBody>
              {stableSort(ticket, getComparator(order, orderBy))?.map(
                (t, index) => {
                  if (!t.room) {
                    t.room = "";
                  }
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if(!t.closed) {
                    return (
                      <TableRow
                    key={t.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" id={labelId} scope="t">
                      {t.id}
                    </TableCell>
                    <TableCell>{t.label}</TableCell>
                    <TableCell>{t.type}</TableCell>
                    <TableCell>
                      <Box
                        width="100%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        backgroundColor={
                          t.priority === "Urgent"
                          ? red[500]
                          : t.priority === "Haut"
                          ? red[300]
                          : t.priority === "Moyen"
                          ? red[100]
                          : t.priority === "Bas"
                          ? blue[300]
                          : blue[300]
                        }
                        borderRadius="4px"
                      >
                        {t.priority}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {!!hotel && hotel.map((h) => {
                        if (h.idMedialog===t.hotel) {
                          return h.name;
                        } else {
                          return null;
                        }
                      })}
                    </TableCell>
                    <TableCell>
                      {!!room && room.map((r) => {
                        if (r.idMedialog===t.room) {
                          return r.number;
                        } else {
                          return null;
                        }
                      })}
                    </TableCell>
                    <TableCell>{t.detail}</TableCell>
                    <TableCell>{t.observation}</TableCell>
                    <TableCell>{t.technician}</TableCell>
                    <TableCell>{t.created_by}</TableCell>
                    <TableCell>{!!t.created_at && t.created_at.split('T')[0]} {!!t.created_at && t.created_at.split('T')[1].split('.')[0]}</TableCell>
                    <TableCell>{t.closed_by}</TableCell>
                    <TableCell>{!!t.closed_at && t.closed_at.split('T')[0]} {!!t.closed_at && t.closed_at.split('T')[1].split('.')[0]}</TableCell>
                    <TableCell>
                      <DoneOutlineOutlinedIcon
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                        onClick={() => {
                          setRecord(t);
                          handleOpenDialog();
                        }}
                      />
                      <CreateOutlinedIcon
                        style={{
                          cursor: "pointer",
                          marginRight: "12px",
                        }}
                        onClick={() => {
                          setRecord(t);
                          handleOpenModify();
                        }}
                      />
                      {/* <DeleteForeverOutlinedIcon
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleDelete(t.id);
                        }}
                      /> */}
                    </TableCell>
                    
                  </TableRow>
                    )
                      }
                  }
                )}
              </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Typography 
            variant="h3" 
            m="20px 0 0 0"
        >
            Cloturés
        </Typography>
      <Box
        m="20px 0 0 0"
        height="50%"
        sx={{
          "& .MuiTableCell-head": {
            backgroundColor: "#8AA294",
          },
          "& .MuiTableCell-body": {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ticket?.length}
            />
            <TableBody>
              {stableSort(ticket, getComparator(order, orderBy))?.map(
                (t, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if(t.closed) {
                    return (
                      <TableRow
                    key={t.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" id={labelId} scope="t">
                      {t.id}
                    </TableCell>
                    <TableCell>{t.label}</TableCell>
                    <TableCell>{t.type}</TableCell>
                    <TableCell>
                      <Box
                        width="100%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        backgroundColor={
                          t.priority === "Urgent"
                          ? red[500]
                          : t.priority === "Haut"
                          ? red[300]
                          : t.priority === "Moyen"
                          ? red[100]
                          : t.priority === "Bas"
                          ? blue[300]
                          : blue[300]
                        }
                        borderRadius="4px"
                      >
                          {t.priority}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {!!hotel && hotel.map((h) => {
                        if (h.idMedialog===t.hotel) {
                          return h.name;
                        } else {
                          return null;
                        }
                      })}
                    </TableCell>
                    <TableCell>
                      {!!room && room.map((r) => {
                        if (r.idMedialog===t.room) {
                          return r.number;
                        } else {
                          return null;
                        }
                      })}
                    </TableCell>
                    <TableCell>{t.detail}</TableCell>
                    <TableCell>{t.observation}</TableCell>
                    <TableCell>{t.technician}</TableCell>
                    <TableCell>{t.created_by}</TableCell>
                    <TableCell>{!!t.created_at && t.created_at.split('T')[0]} {!!t.created_at && t.created_at.split('T')[1].split('.')[0]}</TableCell>
                    <TableCell>{t.closed_by}</TableCell>
                    <TableCell>{!!t.closed_at && t.closed_at.split('T')[0]} {!!t.closed_at && t.closed_at.split('T')[1].split('.')[0]}</TableCell>
                    <TableCell></TableCell>
                    
                  </TableRow>
                    )
                      }
                  }
                )}
              </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Ticketing;